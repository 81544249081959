.support-message-box-style_wrapper_8049 { 
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
 } 
.support-message-box-style_message-box_7525 { 
    border-radius: 20px;
    max-width: 765px;
    margin: 0 auto;
    font-size: 1.25rem;
    padding: 24px;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        padding: 14px;
        font-size: 1.125rem;
    
    }


    
 } 
.support-message-box-style_build-version_9889 { 
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
    width: 100%;
    text-align: left;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        margin-bottom: 0;
    
    }

 } 
.support-message-box-style_support-link_6767 { 
    color: #ffda9f;
    font-style: italic;
 } 
.support-message-box-style_qr-code-wrapper_0860 { 
    margin: 25px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
 } 
.support-message-box-style_qr-code_1244 { 
    margin-top: 1rem;
 } 