.tags-style_tags-wrapper_0158 { 
    display: flex;
    justify-content: flex-start;
    width: 100%;
 } 
.tags-style_tag_9033 { 
    display: flex;
    align-items: center;
    border-radius: 50px;
    color: white;
    position: relative;
    text-align: right;
    font-weight: bold;
    background-color: var(--v_e8zd35zx);
    box-shadow: var(--v_1rxolzpv);
    padding: 2px 15px 3px 15px;
    margin-right: 5px;
    font-size: 1rem;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        padding-left: 10px;
        padding-right: 10px;
        font-size: 0.75rem;
    
    }


    

    svg {
        display: block;
        height: 16px;
        width: auto;
        margin-right: 5px;
        path {
            fill: #fff;
        }
    }
 } 
.tags-style_text-wrapper_5899 { 
    flex-grow: 1;
 } 
.tags-style_multi-number_7000 { 
    margin-right: 5px;
 } 
.tags-style_tag-name_0964 {  } 