.recovery-box-wrapper_recovery-box-wrapper-base_7945 { 
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 430px;
    padding: 30px;
    box-sizing: border-box;
    max-width: calc(100vw - 20px);
 } 
.recovery-box-wrapper_recovery-box-wrapper-responsive_1048 { 
    
    @media (max-width: 926px) and (orientation: landscape) {
        
        width: 450px;
        padding: 25px;
    
    }

 } 
.recovery-box-wrapper_recovery-box-wrapper-tv_8978 { 
    width: 710px;
    padding: 50px;
 } 