.action-buttons-style_wrapper_3934 { 
    display: flex;
    flex-direction: var(--v_txejgg8r);
    align-items: center;
    height: var(--v_1ydu82y3);
    pointer-events: var(--v_xxayzm3a);

    & > div:not(:last-of-type) {
        margin-right: var(--v_3ovqowp2);

        margin-bottom: var(--v_g7akiteh);
    }
 } 