.game-heading-style_tags-wrapper_2768 { 
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
 } 
.game-heading-style_name_1026 { 
    margin: 0;
    padding: 0;
    text-transform: capitalize;

    font-size: 2.2rem;

    

    
    @media (max-device-width: 1024px) {
        
        font-size: 1.5rem;
    
    }

  
  
    @media (max-width: 926px) and (orientation: landscape) {
        
        font-size: 1.4rem;
    
    }

 } 
.game-heading-style_studio_5716 { 
    color: var(--v_66o3g51z);
    font-weight: normal;
    text-transform: capitalize;
    margin: 0;
    font-size: 1.2rem;
    padding-left: 4px;
    margin-bottom: 1rem;

    
    @media (max-device-width: 1024px) {
        
        font-size: 1rem;
    
    }


    
    @media (max-width: 926px) and (orientation: landscape) {
        
        padding-left: 0;
    
    }

 } 