.aside-nav-style_icon_1525 { 
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 50px;
    width: 50px;
    outline: none;
    border-radius: 50%;
    transition: background-color 250ms ease-out;

    svg {
        fill: white;
        height: 30px;
    }

    &:hover {
        background-color: rgba(255, 255, 255, 0.05);
    }

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        height: 34px;
        width: 34px;

        svg {
            height: 20px;
        }
    
    }


    
 } 
.aside-nav-style_wrapper_8572 { 
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 25px;
    position: relative;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        height: 34px;
    
    }

    

    &:hover > .blur-menu-fallback-style_blur-menu-base-fallback-before_2042 {
        display: none;
    }
 } 
.aside-nav-style_aside-nav-selector_8128 { 
    pointer-events: none;
    border-radius: 50%;
    background-color: 'rgba(255, 255, 255, 0.5)';
    height: 100%;
    width: var(--v_mc850wiw);
    position: absolute;
    opacity: 0;
    transform: translate3d(0, 0, 0);
    transition: transform 250ms ease-out, box-shadow 250ms ease-out,
        opacity 250ms ease-out;
 } 
.aside-nav-style_settings-icon_0673 { 
    svg path {
        fill: var(--v_bbaykudz);
    }
 } 
.aside-nav-style_search-icon_7428 { 
    svg path {
        fill: var(--v_s84j0625);
    }
 } 
.aside-nav-style_favorite-icon_5942 { 
    padding-left: 6px;
    padding-right: 6px;

    svg {
        fill: var(--v_qjnutccn);
        transition: fill 600ms ease-out;

        path {
            stroke-width: 2;
            stroke: var(--v_4y1i829d);
            transition: stroke 600ms ease-out;
        }
    }

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        padding-left: 4px;
        padding-right: 4px;
    
    }

    animation: game-item-style_fav-change_0303 600ms
 } 