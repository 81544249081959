.message-box-style_message-wrapper_1332 { 
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
 } 
.message-box-style_text_2777 { 
    align-self: flex-end;
    padding-bottom: 0.25rem;
    margin-left: 0.75rem;
 } 
.message-box-style_icon_0629 { 
    width: 2.5rem;
    height: 2.5rem;

    svg {
        fill: white;
    }
 } 
.message-box-style_message_3173 { 
    line-height: 1.5rem;
 } 
.message-box-style_multiple-messages-wrapper_1717 { 
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .message-box-style_icon_0629 {
        margin-bottom: 1rem;
    }

    .message-box-style_text_2777 {
        align-self: auto;
        text-align: center;
    }
 } 
.message-box-style_box-title-wrapper_4217 { 
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
 } 
.message-box-style_box-title_6330 { 
    font-size: 1.5rem;
 } 
.message-box-style_icon-title_8584 { 
    width: 1.25rem;
    display: inline-block;
    margin-right: 4px;

    svg {
        fill: white;
    }
 } 
.message-box-style_tick_0957 { 
    margin-left: 0.5rem;
    background-color: white;
    color: var(--v_jgcsjo3a);
    padding: 2px 5px;
    border-radius: 25px;
    font-size: 0.75rem;
 } 