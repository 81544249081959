.global-navigation-style_wrapper_0520 { 
    position: absolute;
    z-index: 900;
    margin-top: 10px;
    display: var(--v_px1gx940);
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    user-select: none;
 } 
.global-navigation-style_content_1916 { 
    width: 98vw;

    display: flex;
    align-items: center;
    justify-content: space-between;
 } 
.global-navigation-style_sub-content_9794 { 
    width: 80vw;
    max-width: 1280px;
    display: var(--v_n2bj5dys);
    align-items: center;
    justify-content: center;

    

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        max-width: 90vw;
        min-width: 90vw;
    
    }


  
    @media (max-width: 670px) and (orientation: landscape) {
        
        max-width: 98vw;
        width: auto;
    
    }

 } 
.global-navigation-style_menu-wrapper_5510 { 
    position: relative; // necessary for absolute positioning of some descendants
    flex: 1;
    max-width: 1050px;
    
    @media (max-width: 926px) and (orientation: landscape) {
        
        max-width: 500px;
    
    }

    
    @media (max-width: 670px) and (orientation: landscape) {
        
        max-width: 420px;
    
    }

 } 
.global-navigation-style_logo-wrapper_2337 { 
    width: 200px;
    align-self: stretch;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        max-width: 110px;
    
    }

    
    @media (max-width: 670px) and (orientation: landscape) {
        
        max-width: 100px;
    
    }
;
 } 
.global-navigation-style_aside-nav-wrapper_9441 { 
    position: relative; // necessary for absolute positioning of some descendants
    display: flex;
    justify-content: flex-end;

    width: 200px;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        max-width: 110px;
    
    }


    
    @media (max-width: 670px) and (orientation: landscape) {
        
        max-width: 100px;
    
    }

 } 