.avatar_avatar_0138 { 
    display: flex;
    box-sizing: border-box;
    border-radius: 25px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent;
    width: 100%;
    height: 100%;
    outline: none;
    cursor: pointer;
    -webkit-touch-callout: none;

    ;
 } 