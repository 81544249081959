.game-description-view-style_wrapper_1666 { 
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    
    @media (max-width: 926px) and (orientation: landscape) {
        
        height: 100%;
    
    }

 } 
.game-description-view-style_description-wrapper-minimized_3839 { 
    font-size: 1.2rem;
    line-height: 1.6rem;
    text-align: justify;

    
    @media (max-width: 670px) and (orientation: landscape) {
        
        font-size: 1rem;
        line-height: 1rem;
    
    }


    
    @media (max-width: 926px) and (orientation: landscape) {
        
        flex: 1;
        padding-right: 8px;
        font-size: 1rem;
        line-height: 1.25rem;
    
    }


    
    @media (max-device-width: 1024px) {
        
        flex: 1;
        padding-right: 8px;
        font-size: 1rem;
        line-height: 1.25rem;
    
    }

 } 
.game-description-view-style_expand-wrapper_9584 { 
    display: inline-block;
 } 
.game-description-view-style_scroll-view_1929 { 
    margin-top: 1rem;
 } 
.game-description-view-style_description_2875 { 
    font-size: 1.2rem;
    line-height: 1.6rem;

    
    @media (max-width: 1300px) {
        
        font-size: 1rem;
        line-height: 1.25rem;
    
    }


    
    @media (max-width: 926px) and (orientation: landscape) {
        
        font-size: 1rem;
        line-height: 1.25rem;
    
    }

 } 